import React, { Component } from "react";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const Subhead = ({ text }) => (
  <h2 className="text-xl pb-3 leading-tight">{text}</h2>
);

const InfoPage = ({ data }) => {
  const {
    intro_text,
    contact_title,
    contact_links,
    cv_title,
    cv_entries,
    selected_clients_title,
    selected_clients,
    info_image,
    meta_title,
    meta_description
  } = data.infopage.edges[0].node.data;

  return (
    <Layout>
      <SEO
        keywords={[`spencer wells`, `photographer`, `nyc`]}
        title={meta_title}
        description={meta_description}
      />
      <div className="max-w-6xl w-full mx-auto p-10 md:p-16 flex flex-col lg:flex-row-reverse justify-start lg:items-center">
        <div className="lg:ml-12 w-full">
          <Img fluid={info_image.fluid} alt={info_image.alt} />
        </div>
        <div>
          <div
            className="mb-16 c-rich max-w-md pt-10"
            dangerouslySetInnerHTML={{ __html: intro_text.html }}
          />
          <div className="flex flex-col md:flex-row">
            <div className="pr-16">
              <div>
                <Subhead text={contact_title} />
                {contact_links.map(({ contact_link, contact_text }) => (
                  <a
                    href={contact_link.url}
                    target={contact_link.target}
                    key={contact_text}
                    className="underline hover:no-underline block leading-loose"
                  >
                    {contact_text}
                  </a>
                ))}
              </div>
              <div className="mt-16">
                <Subhead text={cv_title} />
                {cv_entries.map(
                  ({ cv_entry_description, cv_entry_title }, index) => (
                    <div key={index} className="mb-8">
                      <h4 className="italic pb-1">{cv_entry_title}</h4>
                      <p>{cv_entry_description}</p>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="pr-8 mt-16 md:mt-0 w-48">
              <Subhead text={selected_clients_title} />
              <ul>
                {selected_clients.map(({ selected_client_text }, index) => (
                  <li key={index} className="leading-tight pb-3">
                    {selected_client_text}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    infopage: allPrismicInfo {
      edges {
        node {
          data {
            meta_title
            meta_description
            intro_text {
              html
            }
            contact_title
            contact_links {
              contact_text
              contact_link {
                url
              }
            }
            cv_title
            cv_entries {
              cv_entry_title
              cv_entry_description
            }
            selected_clients_title
            selected_clients {
              selected_client_text
            }
            info_image {
              fluid(maxWidth: 800) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
        }
      }
    }
  }
`;

export default InfoPage;
